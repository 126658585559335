.productDetail {
  padding: 0% 17%;
  padding-bottom: 7%;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .productDetail {
    padding: 1% 2% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .productDetail {
    padding: 0% 3% !important;
  }
}
.prodmob {
  font-family: "Almarai-Bold" !important;
  font-size: 11px !important;
  white-space: nowrap !important;
}
.prodmob .Mui-selected > span.MuiTab-wrapper > svg {
  display: block !important;
  margin-bottom: -10px !important;
  font-size: 15px !important;
}

.prodmob > span.MuiTab-wrapper > svg {
  display: none !important;
}
