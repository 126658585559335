.wrapper_faq input {
  width: 100%;
  height: 41px;
  border: 1px solid #6d7d8b;
  border-radius: 10px;
  padding-left: 10px;
  outline: none;
  font-size: 14px;
  padding-right: 33px;
}
