.wrapper_main_privacy {
  padding-bottom: 60px !important;
  overflow: hidden;
}

.wrapper_main_privacy h3 {
  color: #353434;
  font-size: 30px;
  margin: 30px 0px;
  margin-top: 40px;
  /* float: left; */
  width: 100%;
  text-align: center;
}

.wrapper_main_privacy h4 {
  color: #353434;
  font-size: 18px;
  font-weight: 400;
  float: left;
  width: 100%;
}

.wrapper_main_privacy span {
  padding: 10px 0px 10px 0px;
  font-size: 14px;
  /* float: left; */
  width: 100%;
}

.wrapper_main_privacy p {
  padding-left: 79px;
  margin-bottom: 3px;
  width: 100%;
  font-size: 14px;
}

.title_privacy {
  font-size: 18px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  margin-top: 19px;
  font-family: "Almarai-SemiBold";
  color: #353434 !important;
  padding-left: 0px !important;
}

.wrapper_main_privacy strong {
  font-size: 14px;
  font-weight: 800;
  float: left;
  width: 100%;
  font-family: "Almarai-SemiBold";
  padding-left: 80px;
}
.width_bold {
  width: fit-content !important;
  padding-left: 0px !important;
}
.mt_width_bold {
  margin-top: 10px;
}
span.bold {
  float: right;
  padding-left: 6px;
  font-family: "Almarai-Bold";
}

.pl-bold {
  padding-left: 49px !important;
  padding: 9px 0px;
  font-family: "Almarai-SemiBold";
}
.wrapper_main_privacy ul {
  padding-left: 80px !important;
  font-size: 14px;
}
.pl_90 ul {
  padding-left: 130px !important;
}
.pl_80 {
  padding-left: 80px !important;
}
.pl_30 {
  padding-left: 69px !important;
}

.wrapper_main_privacy_ar h4 {
  text-align: right;
}
.wrapper_main_privacy_ar span {
  text-align: right;
  /* float: right; */
  width: fit-content;
}
.wrapper_main_privacy_ar p {
  text-align: right;
  /* float: right; */
}
.wrapper_main_privacy_ar .title_privacy {
  text-align: right;
}
.wrapper_main_privacy_ar strong {
  text-align: right;
  padding-left: 5px !important;
  float: right;
}
.wrapper_main_privacy_ar li {
  float: right;
  clear: right;
  text-align: right;
}

.wrapper_main_privacy_ar ul {
  padding-left: 125px !important;
}

@media (max-width: 768px) {
  .wrapper_main_privacy h3 {
    font-size: 24px;

    margin-top: 25px;
    margin-bottom: 20px !important;
  }

  .title_privacy {
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .wrapper_main_privacy span {
    padding-left: 1px;
    padding-top: 10px;
  }
  .wrapper_main_privacy strong {
    padding-left: 40px;
  }
  .pl_30 {
    padding-left: 40px !important;
  }
  .wrapper_main_privacy p {
    padding-left: 20px;
  }
  .wrapper_main_privacy ul {
    padding-left: 40px !important;
  }
  span.bold {
    float: left;
  }

  .title_privacy {
    font-size: 14px;
    margin-top: 5px;
  }

  .pl-bold {
    padding-left: 15px !important;
  }
  .pl_90 ul {
    padding-left: 40px !important;
  }
  .pl_80 {
    padding-left: 20px !important;
  }

  .wrapper_main_privacy {
    padding-bottom: 30px !important;
  }

  .wrapper_main_privacy h3 {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 7px !important;
  }
  .wrapper_main_privacy h4 {
    margin: 10px 0px 10px 0px;
    font-size: 16px;
  }
}

@media (max-width: 300px) {
  .wrapper_main_privacy h3 {
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 4px !important;
  }
  .wrapper_main_privacy h4 {
    margin: 10px 0px 10px 0px;
    font-size: 14px;
  }

  .wrapper_main_privacy strong {
    font-size: 12px;
    width: 100%;
    padding-left: 9px;
  }

  .wrapper_main_privacy ul {
    padding-left: 20px !important;
  }

  .pl_30 {
    padding-left: 10px !important;
  }
}
