.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  color: #e62483;
  font-weight: bold;
}

.centered {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrap_promotion1 {
  position: relative;
  width: 100% !important;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
}

.player {
  padding: 0% 10%;
  padding-top: 6%;
  padding-bottom: 2%;
}

#widget2 {
  border-radius: 18px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .player {
    padding: 0% 2% !important;
  }
  .winnerlistarrow {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .player {
    padding: 0% 2% !important;
  }
}

.winnerlistarrow {
  position: absolute;
  color: #353434;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(62 62 62 / 20%);
  height: 100%;
  width: 71px;
}
