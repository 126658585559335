@media only screen and (min-width: 320px) and (max-width: 480px) {
  .Termcontainer {
    line-height: 28px;
    padding: 2% 7% !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  .Termcontainer {
    line-height: 28px;
    padding: 2% 7% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Termcontainer {
    line-height: 28px;
    padding: 2% 6% !important;
  }
}
.Termcontainer {
  line-height: 28px;
  padding: 7% 17% 2% 17%;
}
