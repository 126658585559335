div.subBanner > div > div > div > div.slick-slide,
div.mainBanner > div > div > div > div.slick-slide {
  padding: 0px;
}
div.mainBanner > div > div > div > div.slick-slide img {
  width: 100% !important;
}
.subBanner {
  padding-top: 3% !important;
}
.protab > .MuiTab-wrapper,
.prodtab > .MuiTab-wrapper {
  transform: rotate(270deg);
  font-family: "Almarai-Bold";
  font-size: 18px;
  white-space: nowrap;
}

.protab,
.protab_ar {
  height: 13vh;
  margin: 0%;
}
.prodtab,
.prodtab_ar {
  height: 18vh;
  margin: 0%;
}
.protab_ar > .MuiTab-wrapper,
.prodtab_ar > .MuiTab-wrapper {
  transform: rotate(90deg);
  font-family: "Almarai-Bold";
  font-size: 18px;
}
.protab > span.MuiTab-wrapper > svg,
.protab_ar > span.MuiTab-wrapper > svg,
.prodtab > span.MuiTab-wrapper > svg,
.prodtab_ar > span.MuiTab-wrapper > svg {
  display: none !important;
}
.PrivateTabIndicator-colorPrimary-56 {
  background-color: #e2e2e3 !important;
}
/* .protab.Mui-selected > span.MuiTab-wrapper {
  border-bottom: 5px solid #24cde6;
} */
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
/* .PrivateTabIndicator-colorPrimary-47 {
  background-color: #e2e2e3 !important;
} */
.makeStyles-tabs-23 {
  border-right: none !important;
}
.prmolist > .slick-list {
  padding: 2% 0px !important;
}
.promolist > .root-react-component-countdown-timer {
  font-family: "Almarai-Bold";
  top: 14%;
  left: 4%;
  width: 54%;
  position: absolute;
}

.promolist > .root-react-component-countdown-timer {
  color: #f1f1f1;
}
.count {
  margin: 2px;
  padding: 2px 4px;
  border-radius: 2px;
  vertical-align: middle;
}
.promolist {
  cursor: pointer;
}
.root-react-component-countdown-timer {
  color: #f1f1f1;
  font-family: "Almarai-Bold";
}

div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-11.MuiGrid-grid-sm-11
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > span {
  padding: 1px 4px;
  border-radius: 2px;
}

/* div.slick-slide.slick-active.slick-center {
  transform: scale(1.25);
  padding: 36px;
} */

/* div.slick-slide.slick-active.slick-center.slick-current {
  transform: scale(1.25);
  padding: 36px;
} */

.promotionCenter {
  transform: scale(1.25);
  margin: 0px 57px;
}
.slick-slide {
  padding: 16px;
}

@media (min-width: 1281px) {
  /* CSS */
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .prmolist > .slick-list {
    padding: 9% 0px !important;
  }
  .prmolist > .slick-list > .slick-track > .slick-slide {
    padding: 26px !important;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .prmolist > .slick-list > .slick-track > .slick-slide {
    padding: 44px !important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  div.slick-slide.slick-active.slick-center {
    transform: scale(1.1) !important;
    padding: 16px !important;
  }
}
/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .protab,
  .protab_ar {
    height: 9vh;
    margin: 0%;
  }
  .protab > .MuiTab-wrapper,
  .prodtab > .MuiTab-wrapper {
    transform: rotate(270deg);
    font-size: 10px !important;
  }
  .bannerarrow {
    display: none !important;
  }
  .prmolist > .slick-list {
    padding: 2% 5% !important;
  }
  .prmolist > .slick-list > .slick-track > .slick-slide {
    padding: 16px !important;
  }
  .promolist > .root-react-component-countdown-timer {
    font-family: "Almarai-Bold";
    top: 17%;
    left: 4%;
    width: 40%;
    position: absolute;
    font-size: 9px;
  }
  .winnerlist {
    padding: 0% !important;
  }
  .scroll-view {
    margin-right: 0px !important;
  }
}

.prmolist > .slick-list > .slick-track > .slick-slide {
  padding: 25px !important;
}

div.subBanner > div > div > div > div.slick-slide,
div.mainBanner > div > div > div > div.slick-slide {
  transition: none;
  transition: none;
  transition: none;
}
div.subBanner > div > div > div > div.slick-slide:hover,
div.mainBanner > div > div > div > div.slick-slide:hover {
  -webkit-transform: none;
  transform: none;
}
.winnerlist {
  padding: 9% 6%;
}

/*banner button*/

.buynow {
  height: 12%;
  display: inline-block;
  width: 11% !important;
  position: absolute;
  bottom: 12px;
  right: 27.2%;
  cursor: pointer;
  z-index: 49;
}
.buynow > div {
  display: none;
}
.buynow:hover > div {
  display: block;
  z-index: 49;
}
.buynow:hover > img {
  display: none;
}

.buynow1 {
  height: 12%;
  display: inline-block;
  width: 11% !important;
  position: absolute;
  bottom: 12px;
  left: 27.8%;
  cursor: pointer;
  z-index: 49;
}
.buynow1 > div {
  display: none;
}
.buynow1:hover > div {
  display: block;
  z-index: 49;
}
.buynow1:hover > img {
  display: none;
}

.play_btn_en {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 33%;
  cursor: pointer;
  z-index: 3;
  left: 21%;
  width: 17%;
}

.iphone_13_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 20.9%;
  cursor: pointer;
  z-index: 3;
  left: 16.2%;
  width: 1.5%;
}

.iphone_13_mini_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 15.9%;
  cursor: pointer;
  z-index: 3;
  width: 2.5%;
  left: 34.5%;
}
.iphone_13_mini_btn_ar {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 14.9%;
  cursor: pointer;
  z-index: 3;
  width: 2.5%;
  left: 34.5%;
}
.discount_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 1.5%;
  cursor: pointer;
  z-index: 3;
  width: 2.5%;
  left: 16.99%;
}

.discount_btn1 {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 28.2%;
  cursor: pointer;
  z-index: 3;
  width: 1.5%;
  left: 20.64%;
}
.play_btn_en > div {
  display: none;
}
.play_btn_en:hover > div {
  display: block;
  z-index: 49;
}
.play_btn_en:hover > img {
  display: none;
}

.bannerSuzuki_btn {
  height: 20%;
  display: inline-block;
  width: 11% !important;
  position: absolute;
  bottom: 15%;
  right: 30.5%;
  cursor: pointer;
  z-index: 3;
}
.bannerSuzuki_btn > div {
  display: none;
}
.bannerSuzuki_btn:hover > div {
  display: block;
  z-index: 49;
}
.bannerSuzuki_btn:hover > img {
  display: none;
}

.banner3_btn {
  height: 20%;
  display: inline-block;
  width: 12% !important;
  position: absolute;
  bottom: 19%;
  right: 29%;
  cursor: pointer;
  z-index: 3;
}
.banner3_btn > div {
  display: none;
}
.banner3_btn:hover > div {
  display: block;
  z-index: 49;
}
.banner3_btn:hover > img {
  display: none;
}

.banner4_btn {
  height: 20%;
  display: inline-block;
  width: 12% !important;
  position: absolute;
  bottom: 19%;
  right: 6%;
  cursor: pointer;
  z-index: 3;
}
.banner4_btn > div {
  display: none;
}
.banner4_btn:hover > div {
  display: block;
  z-index: 49;
}
.banner4_btn:hover > img {
  display: none;
}

.bannerSVR_btn {
  height: 20%;
  display: inline-block;
  width: 11% !important;
  position: absolute;
  bottom: 15%;
  right: 30.5%;
  cursor: pointer;
  z-index: 3;
}
.bannerSVR_btn > div {
  display: none;
}
.bannerSVR_btn:hover > div {
  display: block;
  z-index: 49;
}
.bannerSVR_btn:hover > img {
  display: none;
}

.banner_fresh_btn {
  height: 20%;
  display: inline-block;
  width: 11% !important;
  position: absolute;
  bottom: 13%;
  right: 46%;
  cursor: pointer;
  z-index: 3;
}
.banner_fresh_btn > div {
  display: none;
}
.banner_fresh_btn:hover > div {
  display: block;
  z-index: 49;
}
.banner_fresh_btn:hover > img {
  display: none;
}

.friday_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 7%;
  right: 74.4%;
  cursor: pointer;
  z-index: 3;
  width: 1.3%;
}
.carbanner_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 12%;
  cursor: pointer;
  z-index: 3;
  width: 2.2%;
  left: 45.3%;
}
.carbanner1_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 18.9%;
  cursor: pointer;
  z-index: 3;
  left: 16.2%;
  width: 1.5%;
}
.carbanner1_btn_ar {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 10%;
  cursor: pointer;
  z-index: 3;
  width: 3%;
  left: 21.6%;
}
.youtube_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 39%;
  cursor: pointer;
  z-index: 3;
  width: 7%;
  left: 48.7%;
}
.crypto_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 8%;
  right: 58.99%;
  cursor: pointer;
  z-index: 3;
  width: 1.3%;
}
.crypto_btn_ar {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 8%;
  left: 19.7%;
  cursor: pointer;
  z-index: 3;
  width: 1.5%;
}
.banner_sale_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 20%;
  left: 16%;
  cursor: pointer;
  z-index: 3;
  width: 1.5%;
}
.banner_sale_btn_ar {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 20%;
  left: 19.6%;
  cursor: pointer;
  z-index: 3;
  width: 2.5%;
}

.banner_iphonepro_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 23%;
  cursor: pointer;
  z-index: 3;
  left: 34.75%;
  width: 1.7%;
}
.banner_iphonepro_btn_ar {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 20%;
  right: 60.75%;
  cursor: pointer;
  z-index: 3;
  width: 1.7%;
}

.carbanner1_btn > div,
.youtube_btn > div,
.carbanner1_btn_ar > div,
.iphone_13_mini_btn > div,
.iphone_13_mini_btn_ar > div,
.discount_btn > div,
.discount_btn1 > div {
  display: none;
}
.carbanner1_btn:hover > div,
.youtube_btn:hover > div,
.carbanner1_btn_ar:hover > div,
.iphone_13_mini_btn:hover > div,
.iphone_13_mini_btn_ar:hover > div,
.discount_btn:hover > div,
.discount_btn1:hover > div {
  display: block;
  z-index: 49;
}

.carbanner1_btn:hover > img,
.youtube_btn:hover > img,
.carbanner1_btn_ar:hover > img,
.iphone_13_mini_btn:hover > img,
.iphone_13_mini_btn_ar:hover > img,
.discount_btn:hover > img,
.discount_btn1:hover > img {
  display: none;
}

.banner_iphonemini_btn {
  height: 20%;
  display: inline-block;
  position: absolute;
  bottom: 16%;
  right: 34%;
  cursor: pointer;
  z-index: 3;
}
.banner_iphonemini_btn > div {
  display: none;
}
.banner_iphonemini_btn:hover > div {
  display: block;
  z-index: 49;
}
.banner_iphonemini_btn:hover > img {
  display: none;
}
/*banner button*/

.track-vertical {
  position: absolute;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  background-color: #d6d6d6;
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
  height: 80px;
  transform: translateY(0px);
}
.scroll-view {
  margin-right: 0px !important;
  overflow: hidden !important;
}

@media (min-width: 320px) and (max-width: 480px) {
  .promotionarrow {
    display: none !important;
  }
}
.promotionarrow {
  position: absolute;
  color: #f1f1f1;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(226 226 227 / 20%);
  height: 100%;
  width: 78px;
  margin: 0px -13px;
}

.bannerarrow {
  position: absolute;
  color: #ffffff;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(123 123 124 / 20%);
  height: 100%;
  width: 58px;
  margin: 0px -10px;
}

.winnerarrow {
  position: absolute;
  color: #f1f1f1;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(226 226 227 / 20%);
  height: 36px;
  width: 100%;
}
.wnextArrow {
  z-index: 1;
  bottom: 0px;
}
.wprevArrow {
  z-index: 1;
  top: 0px;
}
.winnerlistslide > .slick-list > .slick-track > .slick-slide {
  padding: 0px 19px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .arrow {
    display: none !important;
  }
}
.arrow {
  position: absolute;
  color: #f1f1f1;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(226 226 227 / 80%);
  height: 100%;
  width: 71px;
  margin: 0px -13px;
}

.nextArrow {
  top: 0px;
  right: 13px;
}

.bannernextArrow {
  top: 0px;
  right: 10px;
}
.prevArrow {
  z-index: 1;
  top: 0px;
  left: 10px;
}
