@import "~react-component-countdown-timer/lib/styles.css";

body {
  margin: 0;
  font-family: "Almarai-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.Toastify__toast--default {
  background: #353434 !important;
  color: #ffffff !important;
  font-size: 16px !important;
  font-family: "Almarai-Semibold" !important;
  line-height: 1.5rem !important;
}
.Toastify__toast-container--top-right {
  top: 7em !important;
  right: 1em !important;
}
.Toastify__close-button > svg,
.Toastify__close-button--default {
  fill: #ffffff !important;
  height: 19px !important;
  width: 19px !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.MuiTabs-flexContainer {
  display: flex;
  align-items: center !important;
}

.protab.Mui-selected > span.MuiTab-wrapper > svg,
.protab_ar.Mui-selected > span.MuiTab-wrapper > svg,
.prodtab.Mui-selected > span.MuiTab-wrapper > svg,
.prodtab_ar.Mui-selected > span.MuiTab-wrapper > svg,
.prodmob.Mui-selected > span.MuiTab-wrapper > svg {
  display: block !important;
  margin-bottom: -10px !important;
  font-size: 15px !important;
}

[dir="rtl"] .slick-slide {
  float: left !important;
}

.dialogDiv > .MuiPopover-paper {
  top: 67px !important;
  border-radius: 0px 0px 20px 20px !important;
  left: 75% !important;
}

.dialogDiv_ar > .MuiPopover-paper {
  top: 67px !important;
  border-radius: 0px 0px 20px 20px !important;
  left: 8% !important;
}
label + .MuiInput-formControl {
  margin-top: 0px !important;
}

.MuiInputLabel-formControl {
  display: none !important;
}

.slick-slide {
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.slick-slide:hover {
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
  transform: scale3d(1.03, 1.03, 1.03);
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 3px solid #fff !important;
}
.carousel .thumb {
  border: 3px solid #333 !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.carousel .thumbs {
  display: flex !important;
  justify-content: center !important;
}
.MuiBadge-anchorOriginTopRightRectangle.MuiBadge-invisible {
  transform: scale(1) translate(50%, -50%) !important;
}
.MuiListItem-gutters {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: #363636 !important;
}
.react-tel-input .country-list .country {
  font-family: "Almarai-Regular" !important;
  font-size: 14px !important;
}
.react-tel-input .country-list .country .dial-code {
  color: white !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #303030 !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #303030 !important;
}

.react-tel-input .form-control {
  height: 48px !important;
  border-radius: 8px !important;
}

.MuiOutlinedInput-input {
  font-family: "Almarai-SemiBold" !important;
}
.MuiTextField-root::placeholder {
  color: white !important;
}
.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #f7f1f1 !important;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #434343 !important;
  color: -internal-light-dark(black, white) !important;
}
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiPickersCalendarHeader-dayLabel {
  color: aliceblue !important;
}
.MuiPickersDay-current {
  color: #050000 !important;
}
.MuiPickersDay-daySelected {
  color: #fff !important;
  font-weight: 500;
  background-color: #24cde6 !important;
}
.MuiButton-textPrimary {
  color: #ffffff !important;
}
.apple-pay-button {
  padding: 9px !important;
  margin-bottom: 5% !important;
}
.paypal-powered-by {
  display: none;
}
.arrow {
  position: absolute;
  color: #353434;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(226 226 227 / 80%);
  height: 100%;
  width: 71px;
  margin: 0px -13px;
}

.nextArrow {
  top: 0px;
  right: 13px;
}

.prevArrow {
  z-index: 1;
  top: 0px;
  left: 10px;
}

.react-tel-input .selected-flag {
  padding: 0px 8px 0px 8px !important;
}

.react-tel-input .selected-flag .arrow {
  display: none !important;
}

.react-tel-input .form-control {
  padding: 0px 48px !important;
  direction: ltr !important;
}

.googleLogin {
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 31%), 0 2px 2px -2px rgb(0 0 0 / 25%);
  background-color: #f5f8f8;
  font-size: 0.875rem;
  font-family: "Almarai-SemiBold";
  text-transform: uppercase;
  color: black;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  margin-bottom: 5%;
  cursor: pointer !important;
}

.facebook-button {
  background-color: #1877f2;
  font-size: 0.875rem;
  font-family: "Almarai-SemiBold";
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  margin-bottom: 5%;
  width: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer !important;
}

div.mapDiv > div {
  border-radius: 14px !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .protab.Mui-selected > span.MuiTab-wrapper > svg {
    display: block !important;
    margin-bottom: 0px !important;
    font-size: 5px !important;
  }
}
