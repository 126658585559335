/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1280px) {
  .div1 {
    max-width: 27.333333% !important;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .content {
    align-items: center !important;
  }
}
/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 768px) and (max-width: 959px) {
  .content,
  .content1,
  .paymeth,
  .footerbottom {
    padding-bottom: 4% !important;
  }
  .link1 {
    padding: 0px 27px !important;
  }
  .footercolor {
    padding: 5% 2% !important;
  }
  .content1 {
    margin-right: 0%;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .content {
    align-items: center !important;
  }
  .content1,
  .paymeth,
  .footerbottom {
    padding-bottom: 6% !important;
  }
  .paymeth {
    justify-content: center !important;
    margin-left: 0% !important;
  }
  .link1 {
    padding: 0px 27px !important;
  }
  .footercolor {
    padding: 5% 2% !important;
  }
  .content1 {
    margin-right: 0%;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .content {
    align-items: center !important;
  }
  .content1 {
    margin-right: 0%;
  }
  .footerbottom {
    padding: 2% 0% 6% 0% !important;
  }
  .content1,
  .paymeth {
    padding-bottom: 6% !important;
  }
  .paymeth {
    justify-content: center !important;
    margin-left: 0% !important;
  }
  .link1 {
    padding: 0px 27px !important;
  }
  .footercolor {
    padding: 5% 2% !important;
  }

  .logo > img {
    width: 20% !important;
  }
}

.content {
  align-items: flex-start;
  justify-content: start;
  display: flex;
  flex-direction: column;
}
.content2 {
  align-items: flex-start;
  justify-content: start;
  display: flex;
  flex-direction: column;
}
.content1 {
  justify-content: center;
  align-items: center;
  margin-right: 3% !important;
}
.border {
  border-top: 1px solid #ffffff0d;
}
.footerbottom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.payment {
  width: 30%;
}

.paymeth {
  justify-content: center;
  margin-left: 2.2%;
  display: flex;
  align-items: center;
}

.link1 {
  color: #ffffff;
  font-size: 14px;
  font-family: Almarai-Regular;
  padding-right: 5%;
}
.link2 {
  color: #ffffff;
  font-size: 14px;
  font-family: Almarai-Regular;
  padding-right: 2%;
  display: inline-block;
}
.footercolor {
  width: 100%;
  bottom: 0;
  padding: 2% 7%;
  /* position: absolute; */
  text-align: center;
  background-color: #353434;
}
