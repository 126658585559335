@media (min-width: 320px) and (max-width: 480px) {
  .panelcolor {
    padding: 1px !important;
  }
}

.panelcolor {
  opacity: 1;
  padding: 10px 0px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  background-color: #353434;
  position: fixed;
  z-index: 1000;
}

@media (min-width: 942px) and (max-width: 1560px) {
  .hideimg {
    display: none;
  }
}
