.wrap_content_about p {
  margin-bottom: 25px;
}
.wrap_content_promotion p {
  margin-bottom: 0px;
}
.proDetail_en {
  padding-right: 10%;
  background-color: #3e3e3e;
}
.proDetail_ar {
  padding-left: 10%;
  background-color: #3e3e3e;
}
.MuiToggleButton-root.Mui-selected {
  color: #ffffff !important;
  background-color: #747474 !important;
}

.promotionDetailImg > .carousel .slide {
  display: flex;
  justify-content: center;
}
.carousel .carousel-status {
  display: none !important;
}
.carousel .thumbs {
  transition: all 0.15s ease-in;
  transform: translate3d(0, 0, 0);
  position: relative;
  list-style: none;
  white-space: nowrap;
  text-align: center;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 14px;
  overflow: hidden;
  padding: 1px;
  border-radius: 13px;
  width: 16% !important;
  height: 138px;
}
.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 14px;
  overflow: hidden;
  padding: 1px;
  border-radius: 13px;
  width: 16% !important;
  height: 138px;
}

.slick-next:before,
.slick-prev:before {
  line-height: 1;
  opacity: 0.75;
  color: #353434 !important;
  font-size: 29px !important;
}
.morePromotion > .slick-list {
  display: flex !important;
  justify-content: center !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .proDetail_en {
    padding-right: 0% !important;
  }
  .carousel .thumb {
    width: 16% !important;
    height: 64px !important;
  }
  .arrow {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel .thumb {
    width: 24% !important;
    height: 86px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .proDetail_en {
    padding-right: 0% !important;
  }
}

.arrow {
  position: absolute;
  color: #f1f1f1;
  font-size: 2.5em;
  font-weight: bold;
  cursor: pointer;
  background-color: rgb(226 226 227 / 20%);
  height: 100%;
  width: 71px;
  margin: 0px -13px;
}

.nextArrow {
  top: 0px;
  right: 13px;
}

.prevArrow {
  z-index: 1;
  top: 0px;
  left: 10px;
}

.descripDiv > ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
